import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import withRouter from "components/Common/withRouter";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RootState } from 'store/reducers';
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi, isSheet, isImageJpgOrPngOrPdf,presignedUrl,uploadFileToS3 } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
    clearData,
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "containers/Admin/Loader";
import Select from 'react-select';
export interface CreateProps {
    show: boolean;
    onCloseModalQuickView: () => void;
    optionCategory: any[];
}


const Create: FC<CreateProps> = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isApiCall, setIsApiCall] = useState(0)
    const [optionListData, setOptionListData] = useState(false);
    const [optionCategory, setOptionCategory] = useState<string[]>([]);
    const [loderImage, setLoader] = useState(false);
    const [frmData, setFrmData] = useState({
        title: "",
        sub_title: "",
        meta_title: "",
        meta_desc: "",
        raw_material: "",
        case_hardness: "",
        note: "",
        category_id: "",
        sub_category_id: "",
        data_sheet: "",
        product_sheet: "",
        product_table: "",
        line_drawing: "",
        image: "",
        youTube_url:"",
    });

    const onChangeHandler = (event: any) => {
        const { name, value } = event.target;
        setFrmData((preValue) => {
            return {
                ...preValue,
                [name]: value,
            };
        });
    }

    const onChangeSelect = (event: any) => {
        const selectedValues = event.map((subject: any) => subject.value);
        setFrmData((preValue) => {
            return {
                ...preValue,
                "category_id": selectedValues
            };
        });
        //setSelectedData(event)
    };

    const handleImageChange = async (event: any) => {
        const file = event.target.files[0];
        try {
            // Get pre-signed URL from the backend
            const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
            const newData = {
                filename: file.name,
                filetype: file.type,
                folder: 'product_group/images'
            };
            let url = await presignedUrl(apiUrl,newData);
            setLoader(true);
            // Upload the file to S3
            uploadFileToS3(file,url.data)
            .then((data) => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });
            setFrmData((preValue) => {
                return {
                    ...preValue,
                    image: url.filename,
                };
            });

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    }

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        try {
            // Get pre-signed URL from the backend
            const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
            const newData = {
                filename: file.name,
                filetype: file.type,
                folder: 'product_group/pdf'
            };
            let url = await presignedUrl(apiUrl,newData);

            setLoader(true);
            // Upload the file to S3
            uploadFileToS3(file,url.data)
            .then((data) => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });

            setFrmData((preValue) => {
                return {
                    ...preValue,
                    data_sheet: url.filename,
                };
            });

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    }

    const handleLineDrawingChange = async (event: any) => {
        const file = event.target.files[0];
        try {
            // Get pre-signed URL from the backend
            const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
            const newData = {
                filename: file.name,
                filetype: file.type,
                folder: 'product_group/line_drawing'
            };
            let url = await presignedUrl(apiUrl,newData);

            setLoader(true);
            // Upload the file to S3
            uploadFileToS3(file,url.data)
            .then((data) => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });

            setFrmData((preValue) => {
                return {
                    ...preValue,
                    line_drawing: url.filename,
                };
            });

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    }

    const handleSheetChange = (event: any) => {
        const file = event.target.files[0];
        setFrmData((preValue) => {
            return {
                ...preValue,
                product_sheet: file,
            };
        });
    }

    const handleTableChange = (event: any) => {
        const file = event.target.files[0];
        setFrmData((preValue) => {
            return {
                ...preValue,
                product_table: file,
            };
        });
    }
    useEffect(() => {
        if (isApiCall == 0) {
            getCategoryListData();
            setIsApiCall(1);
        }
    }, [isApiCall]);

    const { data } = useSelector((state: RootState) => state.AppMaster);

    const getCategoryListData = () => {
        if (optionCategory.length == 0) {
            setOptionListData(true);
            dispatch(clearData())
            const filterData = {};
            dispatch(onGetList(filterData, "", api.categoryList));
        }
    }

    if (data != null && optionListData == true && optionCategory.length == 0) {
        if (data.length != 0) {
            setOptionListData(false);
            const categoryList = data.result
            .map((subject: any) => ({
                value: subject._id,
                label: subject.name,
            }));
            setOptionCategory(categoryList);
        }
    }


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            title: (frmData && frmData.title) || "",
            sub_title: (frmData && frmData.sub_title) || "",
            meta_title: (frmData && frmData.meta_title) || "",
            meta_desc: (frmData && frmData.meta_desc) || "",
            raw_material: (frmData && frmData.raw_material) || "",
            case_hardness: (frmData && frmData.case_hardness) || "",
            note: (frmData && frmData.note) || "",
            category_id: (frmData && frmData.category_id) || "",
            sub_category_id: (frmData && frmData.sub_category_id) || "",
            image: (frmData && frmData.image) || "",
            data_sheet: (frmData && frmData.data_sheet) || "",
            product_sheet: (frmData && frmData.product_sheet) || "",
            product_table: (frmData && frmData.product_table) || "",
            line_drawing: (frmData && frmData.line_drawing) || "",
            youTube_url: (frmData && frmData.youTube_url) || "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title"),
            sub_title: Yup.string().required("Please Enter Sub Title"),
            product_sheet: Yup.mixed()
                .test('fileFormat', 'Product sheet must be an xlsx.', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isSheet(value);
                }),
            product_table: Yup.mixed()
                .test('fileFormat', 'Product table must be an xlsx.', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isSheet(value);
                }),
            // data_sheet: Yup.string().required("Please Select Catalog File"),
          /*   image: Yup.mixed()
                .test('fileFormat', 'Image must be an jpeg, jpg or png', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isImageJpgOrPng(value);
                }).required("Please Select Product image File"),
            line_drawing: Yup.mixed()
                .test('fileFormat', 'Line drawing must be an jpeg, jpg, png or pdf.', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isImageJpgOrPngOrPdf(value);
                }).required("Please Select line drawing File"), */
        }),
        onSubmit: values => {
            const newData = {
                title: values["title"],
                sub_title: values["sub_title"],
                meta_title: values["meta_title"],
                meta_desc: values["meta_desc"],
                raw_material: values["raw_material"],
                case_hardness: values["case_hardness"],
                note: values["note"],
                category_id: values["category_id"],
                sub_category_id: values["sub_category_id"],
                image: values["image"],
                data_sheet: values["data_sheet"],
                product_table:values["product_table"],
                product_sheet: values["product_sheet"],
                line_drawing: values["line_drawing"],
                youTube_url: values["youTube_url"],
            };
            //  dispatch(onAddNewData(newData, "", api.createCategory))
            const url = process.env.REACT_APP_API_URL + api.productGroupCreate
            callMultipartFormDataApi(url, newData)
            // navigate("/admin/products");
        },
    });

    return (
        <div className="container py-16 lg:pb-28 lg:pt-20">
            <Helmet>
                <title>Product Group</title>
            </Helmet>
            <Loader
                loderImage={loderImage}
            />
            <div className="mb-12 sm:mb-16">
                <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Add Product Group</h2>
            </div>
            <div className="mt-8 sm:mt-10">
                <div className="flex-1  rounded-xl hiddenScrollbar">
                    <form className="grid grid-cols-1 md:grid-cols-2 gap-6"
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        method="post">
                        <label className="block">
                            <Label>Title</Label>
                            <Input
                                name="title"
                                placeholder="Title"
                                type="text"
                                className="mt-1"
                                value={frmData.title}
                                onChange={onChangeHandler}
                            />
                            {validation.errors.title ? (
                                <small className="text-rose-600">{validation.errors.title}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Sub Title</Label>
                            <Input
                                name="sub_title"
                                placeholder="Sub Title"
                                type="text"
                                className="mt-1"
                                value={frmData.sub_title}
                                onChange={onChangeHandler}
                            />
                            {validation.errors.sub_title ? (
                                <small className="text-rose-600">{validation.errors.sub_title}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Category</Label>
                            <Select
                                name="category_id"
                                options={optionCategory}
                                isMulti={true}
                                onChange={(event:any) => onChangeSelect(event)}
                                className="mt-1"
                            />
                            {validation.errors.category_id ? (
                                <small className="text-rose-600">{validation.errors.category_id}</small>
                            ) : null}
                        </label>
                        {/*    <label className="block">
                <Label>Sub Category</Label>
                <Select
                name="sub_category_id"
                className="mt-1"
                onChange={(event) => onChangeSelect(event, "sub_category_id")}
                />
                {validation.errors.sub_title ? (
                    <small className="text-rose-600">{validation.errors.sub_title}</small>
                ) : null}
            </label> */}
                        <label className="block">
                            <Label>Raw Material</Label>
                            <Textarea
                                name="raw_material"
                                placeholder="Raw Material"
                                rows={2}
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Case Hardness</Label>
                            <Textarea
                                name="case_hardness"
                                rows={2}
                                placeholder="Case Hardness"
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Note</Label>
                            <Textarea
                                name="note"
                                rows={2}
                                placeholder="Note"
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Meta Title</Label>
                            <Textarea
                                name="meta_title"
                                rows={2}
                                placeholder="Meta Title"
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Meta Description</Label>
                            <Textarea
                                name="meta_desc"
                                rows={2}
                                placeholder="Meta Description"
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Youtube URL</Label>
                            <Textarea
                                name="youTube_url"
                                rows={2}
                                placeholder="Enter URL"
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Image</Label>
                            <Input
                                type="file"
                                name="image"
                                placeholder=""
                                className="mt-1"
                                onChange={handleImageChange}
                            />
                            {validation.errors.image ? (
                                <small className="text-rose-600">{validation.errors.image}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Catalog File</Label>
                            <Input
                                type="file"
                                name="data_sheet"
                                placeholder=""
                                className="mt-1"
                                onChange={handleFileChange}
                            />
                            {validation.errors.data_sheet ? (
                                <small className="text-rose-600">{validation.errors.data_sheet}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Line Drawing</Label>
                            <Input
                                type="file"
                                name="line_drawing"
                                placeholder=""
                                className="mt-1"
                                onChange={handleLineDrawingChange}
                            />
                            {validation.errors.line_drawing ? (
                                <small className="text-rose-600">{validation.errors.line_drawing}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Product Table</Label>
                            <Input
                                type="file"
                                name="product_sheet"
                                placeholder=""
                                className="mt-1"
                                onChange={handleSheetChange}
                            />
                            {validation.errors.product_sheet ? (
                                <small className="text-rose-600">{validation.errors.product_sheet}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Raw Material Table</Label>
                            <Input
                                type="file"
                                name="product_table"
                                placeholder=""
                                className="mt-1"
                                onChange={handleTableChange}
                            />
                            {validation.errors.product_table ? (
                                <small className="text-rose-600">{validation.errors.product_table}</small>
                            ) : null}
                        </label>
                        <div className="mt-4">
                            <ButtonPrimary type="submit">Submit</ButtonPrimary>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Create);
