import React, { FC, useEffect, useState,useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import LoaderSpinner from "containers/Admin/LoaderSpinner";

export interface AdminUserProps {
  // data:any;
  result:any[]
}


const Index: FC<AdminUserProps> = (props) => {
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0)
  const [listCount, setListCount] = useState(0);
  const [listData, setListData] = useState([]);
  const [editListData, setEditData] = useState([]);
  const [updateListData, setUpdateListData] = useState(false);
  const [optionListData, setOptionListData] = useState(false);
  const [changeStatus, setChangeStatus] = useState('');
  const [showEditModalQuickView, setEditShowModalQuickView] = useState(false);
  const [optionCategory, setOptionCategory] =  useState<string[]>([]);
  const [filterData, setFilterData] = useState({
      start: 0,
      limit: 10,
      search: '',
      sort_by: "_id",
      sort_order:"desc",
  });

  useEffect(() => {
    if (isApiCall == 0) {
      getListData();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

   /**Change Status */
   const handleStatusChange = (status:string, id:string) => {
    // console.log(status);

      if (status == 'Active') {
          status = 'Inactive';
      } else {
          status = 'Active';
      }
      const updateData = {
          status: status,
          id: id,
      };
      dispatch(onUpdateData(updateData,'', api.productGroupUpdateStatus))
      setIsApiCall(0);
    }

  const handleEditClick = (arg:any) => {
      setEditData(arg);
      setEditShowModalQuickView(true)
  };

  const handleSearch = async (name:any, value:any) => {
    if (name === 'start') {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        start: value,
      }));
    }
  };

  const getListData = () => {
    setUpdateListData(true);
    dispatch(clearData())
    dispatch(onGetList(filterData, '', api.productGroupList));
  }

  if (data!=null && updateListData == true) {


     if(data.length!=0){
        setUpdateListData(false);
        setListData(data.result);
        setListCount(data.total_count);
     }
  }

  const columns = useMemo(
    () => [
        {
            Header: "Title",
            Cell: (cellProps:any) => {
              return (
                  <div className="flex gap-3">
                      <a href={`/admin/product/${cellProps.row.original._id}`}
                        className="text-secondary"
                      >
                        {cellProps.row.original.title}
                      </a>
                  </div>
              );
          },
        },
        {
            Header: "Sub Title",
            accessor: 'sub_title',
        },
        {
            Header: "Category",
            Cell: (cellProps: any) => {
                const category = cellProps.row.original.category;
                return (
                    <div className="d-flex gap-3">
                        <span>{category && category.length > 0 ? category[0].name : 'No Category'}</span>
                    </div>
                );
            }
        },
        {
            Header: "Status",
            Cell: (cellProps:any) => {
                return (
                    <div className="d-flex gap-3">
                        <Link to="#" onClick={() => {
                            handleStatusChange(cellProps.row.original.status, cellProps.row.original._id);
                        }}><span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset  ${cellProps.row.original.status == 'Active' ? "bg-green-50 text-green-600 ring-green-500/10" : "bg-red-50 text-red-600 ring-red-500/10"}`}
                        >{cellProps.row.original.status}
                            </span></Link>
                    </div>
                );
            }
        },
        {
            Header: "Action",
            Cell: (cellProps:any) => {
              return (
                  <div className="flex gap-3">
                     <a href={`/admin/product/edit/${cellProps.row.original._id}`}
                        className="text-secondary"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 576 512"><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg>
                      </a>
                      <a href={`/admin/product/${cellProps.row.original._id}`}
                        className="text-secondary"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg"  className="w-4 h-4" viewBox="0 0 192 512"><path d="M20 424.2h20V279.8H20c-11 0-20-9-20-20V212c0-11 9-20 20-20h112c11 0 20 9 20 20v212.2h20c11 0 20 9 20 20V492c0 11-9 20-20 20H20c-11 0-20-9-20-20v-47.8c0-11 9-20 20-20zM96 0C56.2 0 24 32.2 24 72s32.2 72 72 72 72-32.2 72-72S135.8 0 96 0z"/></svg>
                      </a>
                  </div>
              );
          },
        }
    ],
    []
  );

 // Check the console for the columns
  const page =1;



  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>Product Group</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Product Group</h2>
      </div>
      <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">

        <Datatable
          listData={listData}
          filterData={filterData}
          getListData={getListData}
          listCount={listCount}
          columns={columns}
          editData={editListData}
          handleSearch={handleSearch}
        />
      </div>
      <LoaderSpinner />
    </div>
  );
};

export default withRouter(Index);
